/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
       
 		   	//--------------------------------------------------------------------------------//
			// TOGGLE STATIC CONTENT
			// -------------------------------------------------------------------------------//
			$('.toggle-wrap h4').wrap('<div class="toggle"></div>');
			$('.toggle-wrap:first-child').addClass('visible');
			$('.toggle h4').append('<i class="fa  fa-chevron-down"></i>');
			$('.toggle-wrap .fa').css({'float' : 'right'});
			$('.toggle:first-child').show();
			$('.toggle-wrap').each(function(){
				$(this).find('p, ul').wrapAll('<div class="tHidden"></div>');
			});

				//KEEP OPEN THE FIRST ONE
				$('.toggle-wrap').first().find('.tHidden').css({'display' : 'block'});
				$('.toggle-wrap h4').first().find('.fa').css({'transform' : 'rotate(180deg)'});


			$('.toggle').click(function(e){
				if($(this).next().is(':hidden')){
					$(this).next().slideDown();

					jQuery(this).animate({ borderSpacing: 180 }, {
						step: function(now,fx) {
							$(this).find('.fa').css('-webkit-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('-moz-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('transform','rotate('+now+'deg)');
						},
					duration:'medium'
					},'linear');

				} else {
					$(this).next().slideUp();

					jQuery(this).animate({ borderSpacing: 0 }, {
						step: function(now,fx) {
							$(this).find('.fa').css('-webkit-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('-moz-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('transform','rotate('+now+'deg)');
						},
					duration:'medium'
					},'linear');

				}
				$('.toggle-wrap h4').click(function(e){
					e.preventDefault();
				});
			});
			
			
			
			//--------------------------------------------------------------------------------//
			// TOGGLE Content for Club psges
			// -------------------------------------------------------------------------------//
			$('.toggle-wrap-club h3').wrap('<div class="toggle-club"></div>');
			$('.toggle-wrap-club:first-child').addClass('visible');
			$('.toggle-club h3').append('<i class="fa  fa-chevron-down"></i>');
			$('.toggle-wrap-club .fa').css({'float' : 'right'});
			$('.toggle-club:first-child').show(); 


				//KEEP OPEN THE FIRST ONE
			 	$('.toggle-wrap-club').first().find('.tHidden').css({'display' : 'block'});
				$('.toggle-wrap-club h3').first().find('.fa').css({'transform' : 'rotate(180deg)'});
				
			$('.toggle-club').click(function(e){
				if($(this).next().is(':hidden')){
					$(this).next().slideDown();

					jQuery(this).animate({ borderSpacing: 180 }, {
						step: function(now,fx) {
							$(this).find('.fa').css('-webkit-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('-moz-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('transform','rotate('+now+'deg)');
						},
					duration:'medium'
					},'linear');

				} else {
					$(this).next().slideUp();

					jQuery(this).animate({ borderSpacing: 0 }, {
						step: function(now,fx) {
							$(this).find('.fa').css('-webkit-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('-moz-transform','rotate('+now+'deg)');
							$(this).find('.fa').css('transform','rotate('+now+'deg)');
						},
					duration:'medium'
					},'linear');

				}
				$('.toggle-wrap-club h3').click(function(e){
					e.preventDefault();
				});
			});
			
			
			equalheight = function(container){

			var currentTallest = 0,
			currentRowStart = 0,
			rowDivs = [],
			$el,
			topPosition = 0;

			$(container).each(function() {

			$el = $(this);
			$($el).height('auto');
			topPostion = $el.position().top;

			if (currentRowStart !== topPostion) {
				for (currentDiv = 0; currentDiv < rowDivs.length ; currentDiv++) {
					rowDivs[currentDiv].height(currentTallest);
				}
			rowDivs.length = 0; // empty the array
				currentRowStart = topPostion;
				currentTallest = $el.height();
				rowDivs.push($el);
			} else {
				rowDivs.push($el);
				currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
			}
			for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				rowDivs[currentDiv].height(currentTallest);
				}
			});
			};


			$(window).load(function() {
				equalheight('.equal');
			});


			$(window).resize(function() {
				equalheight('.equal');
			});
			
			
		/*
	$(".navbar-toggle").click(function(){
		
				console.log("Click");
			}); 
			
*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

		  if(/chrom(e|ium)/.test(navigator.userAgent.toLowerCase())){
		  	 $(".text-wrap").addClass("chrome");
		  }else if(/firefox/.test(navigator.userAgent.toLowerCase())){
			 $(".text-wrap").addClass("firefox");
		  }

		  $(window).stellar();
		  
		  
		$(".button0").mouseover(function(){
			$(".triangle0").show();
		}).mouseout(function() {
			$(".triangle0").hide();
		});
		
		$(".button1").mouseover(function(){
			$(".triangle1").show();
		}).mouseout(function() {
			$(".triangle1").hide();
		});
		
		$(".button2").mouseover(function(){
			$(".triangle2").show();
		}).mouseout(function() {
			$(".triangle2").hide();
		});

		  
		  
		  

          var slider = $('.slider-wrap').bxSlider({
          		  controls: true,
		  		  pager: true,
		  		  auto: true,
		  		  speed: 2000,
		  		  pause: 5000,
		  		  pagerCustom: '#bx-pager',
		  		  nextSelector: '#headernext',
		  		  prevSelector: '#headerprev',
		  		  nextText: '<i class="fa fa-chevron-right"></i>',
		  		  prevText: '<i class="fa fa-chevron-left"></i>', 	
		  		  
		  		  onSlideAfter: function(){
			  		  var total = slider.getCurrentSlide();
			  		  
			  		  if(total === 0){
				  		  $(".triangle0").fadeIn();
			  		  }else{
				  		  $(".triangle0").fadeOut();  
			  		  }
			  		  
			  		  if(total === 1){
				  		  $(".triangle1").fadeIn();
			  		  }else{
				  		   $(".triangle1").fadeOut();  
			  		  }
			  		  
			  		  if(total === 2){
				  		  $(".triangle2").fadeIn();
			  		  }else{
				  		  $(".triangle2").fadeOut();  

			  		  }

		  		  },		  		  	  
		  });
	
	$('#headernext').click(function(){
      		slider.goToNextSlide();
	  		return false;
	  	});

	  	$('#headerprev').click(function(){
		  	slider.goToPrevSlide();
		  	return false;
		});
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
